import accordion from './modules/accordion';
import burger from './modules/burger';
import sliders from './modules/slider';
import modal from './modules/modal';
import validForm from './modules/valid';
import timer from './modules/timer';
import forms from './modules/form';
import scrolling from './modules/scrolling';
window.addEventListener('DOMContentLoaded', () => {
   "use strict";

   let deadline = '2020-06-23';
   accordion('.header__contact-head');
   accordion('.header__maps-head');
   burger();
   sliders('.team__slide', '.slider__right', '.slider__prev', '.slider__next', '.pagination .slide__all-pagination', '.pagination .slider__pagination-active');
   modal('.pop-up', '.pop-up__close');
   validForm();
   timer('.pay__timer', deadline);
   forms();
   scrolling();
});