const accordion = (trigger, selectorBox) => {
	const triggerDown = document.querySelectorAll(trigger);
	triggerDown.forEach(trigger => {
		trigger.addEventListener('click', (e) => {
			e.preventDefault();
			trigger.nextElementSibling.classList.toggle('header__contact-body--active');
		});
	});
};

export default accordion;