const modal = (modal, closeBtn) => {
   const modalWindow = document.querySelector(modal),
      btnTrigger = document.querySelectorAll('[data-trigger-modal]'),
      btnClose = document.querySelector(closeBtn);

   btnTrigger.forEach(btn => {
      btn.addEventListener('click', (e) => {
         e.preventDefault();
         modalWindow.classList.add('_active-popup', 'animated', 'fadeIn');
         document.body.style.overflow = 'hidden';
      });
      btnClose.addEventListener('click', () => {
         modalWindow.classList.remove('_active-popup');
         document.body.style.overflow = '';
      });
      document.documentElement.addEventListener('click', (e) => {
         let target = e.target;
         if (target && target.classList.contains('pop-up')) {
            modalWindow.classList.remove('_active-popup');
            document.body.style.overflow = '';
         }
      });
   });
};
export default modal;