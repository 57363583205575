const timer = (id, deadline) => {
   const addZero = (num) => {
      if (num <= 9) {
         return '0' + num;
      } else {
         return num;
      }
   };
   const getTimeRemaining = (endtime) => {
      const t = Date.parse(endtime) - Date.parse(new Date()),
         seconds = Math.floor((t / 1000) % 60),
         minutes = Math.floor((t / 1000 / 60) % 60),
         hours = Math.floor((t / (1000 * 60 * 60) % 24));

      return {
         'total': t,
         'hours': hours,
         'minutes': minutes,
         'seconds': seconds
      };
   };

   const setClock = (selector, endtime) => {
      const timer = document.querySelector(selector),
         hourse = timer.querySelector("#hourse"),
         minutes = timer.querySelector("#minutes"),
         seconds = timer.querySelector("#seconds"),
         timeInterval = setInterval(updateClock, 1000);
      console.log(minutes);

      function updateClock(e) {
         const t = getTimeRemaining(endtime);
         hourse.innerHTML = '<p class="parseSpanH">' + addZero(t.hours) + '</p>';
         minutes.innerHTML = '<p class="parseSpanM">' + addZero(t.minutes) + '</p>';
         seconds.innerHTML = '<p class="parseSpanS">' + addZero(t.seconds) + '</p>';
         // hours
         let parseIntH = document.querySelector('.parseSpanH').textContent;
         let numberParseSpanH = parseIntH.replace(/(.)/g, '<span>$1</span>');
         document.querySelector('.parseSpanH').innerHTML = numberParseSpanH;
         // minutes
         let parseIntM = document.querySelector('.parseSpanM').textContent;
         let numberParseSpanM = parseIntM.replace(/(.)/g, '<span>$1</span>');
         document.querySelector('.parseSpanM').innerHTML = numberParseSpanM;
         // seconds
         let parseIntS = document.querySelector('.parseSpanS').textContent;
         let numberParseSpanS = parseIntS.replace(/(.)/g, '<span>$1</span>');
         document.querySelector('.parseSpanS').innerHTML = numberParseSpanS;



         if (t.total <= 0) {
            hourse.innerHTML = "<p><span>0</span><span>0</span></p>";
            minutes.innerHTML = "<p><span>0</span><span>0</span></p>";
            seconds.innerHTML = "<p><span>0</span><span>0</span></p>";
            clearInterval(timeInterval);
         }
      }

   };


   setClock(id, deadline);

};

export default timer;