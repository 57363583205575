const validForm = () => {
   const nameInput = document.querySelector('[data-name]'),
      emailInpute = document.querySelector('[data-email]'),
      phoneInput = document.querySelector('[data-tel]'),
      form = document.querySelector('.form-body'),
      btnForm = document.querySelector('[data-btn]');

   btnForm.addEventListener('mouseover', () => {
      console.log('Ховер');

      btnForm.disabled = false;
      if (nameInput.value == '') {
         btnForm.disabled = true;
         nameInput.style.border = '1px solid red';
      } else {
         btnForm.disabled = false;
         nameInput.style.border = '0';
      }


      if (emailInpute.value == '') {
         btnForm.disabled = true;
         emailInpute.style.border = '1px solid red';
      } else {
         btnForm.disabled = false;
         emailInpute.style.border = '0';
      }

      if (phoneInput.value == '') {
         btnForm.disabled = true;
         phoneInput.style.border = '1px solid red';
      } else {
         btnForm.disabled = false;
         phoneInput.style.border = '0';
      }
   });

   nameInput.addEventListener('input', function (e) {
      if (this.value == '') {
         this.style.border = '1px solid red';
         btnForm.disabled = true;
      } else {
         this.style.border = '0';
         btnForm.disabled = false;

      }
   });
   phoneInput.addEventListener('input', function (e) {
      if (this.value == '') {
         this.style.border = '1px solid red';
         btnForm.disabled = true;

      } else {
         this.style.border = '0';
         btnForm.disabled = false;

      }
   });

   emailInpute.addEventListener('input', function (e) {
      if (this.value == '') {
         this.style.border = '1px solid red';
         btnForm.disabled = true;
      } else {
         this.style.border = '0';
         btnForm.disabled = false;
      }
   });

};

export default validForm;