const burger = () => {
	const triggerBurger = document.querySelector('.header__burger'),
		navMenu = document.querySelector('.header__nav');

	triggerBurger.addEventListener('click', function () {
		this.classList.toggle('_active');
		navMenu.classList.toggle('_active-nav');
	});
};

export default burger;