const sliders = (slidesImg, slides, prev, next, totalPag, activePag) => {
	let slideIndex = 1,
		slideBoxIndex = 1;
	const slideImgBox = document.querySelectorAll(slidesImg),
		slideContentBox = document.querySelectorAll(slides),
		prevBtn = document.querySelector(prev),
		nextBtn = document.querySelector(next),
		totalSlide = document.querySelector(totalPag),
		activeSlide = document.querySelector(activePag);

	showSlidesImg(slideIndex);
	showSlidesBox(slideBoxIndex);
	if (slideImgBox.length < 10) {
		totalSlide.textContent = `0${slideImgBox.length}`;
	} else {
		totalSlide.textContent = slideImgBox.length;
	}


	function showSlidesImg(n) {

		if (n > slideImgBox.length) {
			slideIndex = 1;
		}
		if (n < 1) {
			slideIndex = slideImgBox.length;
		}

		slideImgBox.forEach(slideImg => {
			setTimeout(() => {
				slideImg.classList.remove('_visibility-slide');
				slideImg.classList.add('_hidden-slide');
			}, 1000);
			slideImg.classList.add('_fadeInOut');
		});
		setTimeout(() => {
			slideImgBox[slideIndex - 1].classList.remove('_fadeInOut');
			slideImgBox[slideIndex - 1].classList.remove('_hidden-slide');
			slideImgBox[slideIndex - 1].classList.add('_visibility-slide');

		}, 1000);
		if (slideImgBox.length < 10) {
			activeSlide.textContent = `0${slideIndex}`;
		} else {
			activeSlide.textContent = slideIndex;
		}
	}

	function showSlidesBox(b) {
		if (b > slideContentBox.length) {
			slideBoxIndex = 1;
		}
		if (b < 1) {
			slideBoxIndex = slideContentBox.length;
		}
		slideContentBox.forEach(slideBox => {
			setTimeout(() => {
				slideBox.classList.add('slide_animated-content--hidden');
			}, 1000);
			slideBox.classList.add('slide_animated-content');
		});
		setTimeout(() => {
			slideContentBox[slideBoxIndex - 1].classList.remove('slide_animated-content--hidden');
			slideContentBox[slideBoxIndex - 1].classList.remove('slide_animated-content');
			slideContentBox[slideBoxIndex - 1].classList.add('slide_animated-content-visibility');
		}, 1000);
	}

	function plusSlide(n, b) {
		showSlidesImg(slideIndex += n);
		showSlidesBox(slideBoxIndex += b);


	}
	prevBtn.addEventListener('click', () => {
		plusSlide(-1, -1);
	});
	nextBtn.addEventListener('click', () => {
		plusSlide(1, 1);
	});

};

export default sliders;